import React from 'react'
import { Grid, Responsive, Input, Form, GridColumn, GridRow } from 'semantic-ui-react'
import { H3, Paragraph1, Paragraph2, } from '@styles/Global.styles';
import { LoadingIcon } from '@components/OrderConfirmation/OrderConfirmation.styles';
import KpButton from '@elements/KpButton/KpButton';
import theme from '@styles/theme';
import { PaymentService } from '@services/PaymentService';
import { PaymentRequestModel } from '@models/Payment/PaymentRequestModel';
import { PaymentLinkMessageIcon, PaymentRequestContentColumn, PaymentRequestTab, PaymentRequestContentGrid, PaymentRequestSummaryRow, PaymentRequestHeading, PaymentRequestItemsContainer, PaymentAmountContainer, PaymentOptionRadioButton, PaymentOptionRadio, TabContentOptionStyle, PaymentRequestOptionRadioButton, ButtonWrapperStyle } from './MakePayment.styles';
import { CartLineItemRow, PlateImageSectionGridColumn, PlateImageSectionHeading, PlateImageContainer, PlateDetailSection, PlateDetailSectionHeading, HeadingRangeName, PlateDetailSectionSize, PlateDetailsGroup, PlateDetailsGroupLabel, PlateDetailsGroupField, CombinationNumber, PurchaseTypeName, PlateItemPriceComputer, PlateItemPrice } from '@components/ShoppingCart/ShoppingCart.styles';
import { PlateImageTitle, PlateImage } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles';
import { KpFormField } from '@styles/Global.forms';
import InfoModal from '@components/InfoModal/InfoModal';
import { PaymentOptions } from '@utils/Constant';
import AfterpayLogo from '@assets/svgs/AfterpayLogo';

interface PaymentRequestProps {
    ccDescription: string,
    a2aDescription: string,
    afterpayDescription: string,
    apirUrl: string,
    token: string,
    fromSalesforce: boolean,
    gemDescription: string
}

interface PaymentRequestState {
    showError: boolean,
    showModal: boolean,
    errorMessage: string,
    loadingPayment: boolean,
    customPaymentAmount: number,
    customPaymentAmountError: string,
    paymentUrl: string,
    allowAfterpay: boolean,
    userCanProceed: boolean,
    loadingPaymentRequest: boolean,
    loadedPaymentRequest: boolean,
    paymentData: PaymentRequestModel | null,
    selectedPaymentOption: number
    isGem: boolean | false
}

class PaymentRequest extends React.Component<PaymentRequestProps, PaymentRequestState> {

    constructor(props: PaymentRequestProps) {
        super(props);

        var isGem = false;
        if (typeof window !== 'undefined'){
            isGem = window.location.href.includes('isGem=True');
        }

        this.state = {
            errorMessage: '',
            showModal: false,
            customPaymentAmount: 0,
            customPaymentAmountError: '',
            loadingPayment: true,
            showError: false,
            allowAfterpay: false,
            paymentUrl: '',
            paymentData: null,
            userCanProceed: false,
            loadingPaymentRequest: false,
            loadedPaymentRequest: false,
            selectedPaymentOption: PaymentOptions.CreditCard,
            isGem: isGem
        }
    }

    componentDidMount() {
        this.loadPaymentRequest();
    }

    loadPaymentRequest() {
        const { apirUrl, token } = this.props;

        if (token) {
            PaymentService.getPaymentRequestData(apirUrl, token, this.props.fromSalesforce).then((data: PaymentRequestModel) => {
                if (data.AllowedToProceed) {
                    this.setState({
                        loadingPayment: false,
                        paymentData: data,
                        allowAfterpay: data.AllowAfterpay,
                        userCanProceed: data.AllowedToProceed,
                        customPaymentAmount: data.AmountDueincGST,
                        errorMessage: '',
                        showError: false,
                        selectedPaymentOption: PaymentOptions.CreditCard
                    });
                }
                else {
                    this.setState({
                        loadingPayment: false,
                        paymentData: null,
                        userCanProceed: data.AllowedToProceed,
                        errorMessage: data.Reason,
                        showError: true
                    })
                }
            });
        }
        else {
            this.setState({
                loadingPayment: false,
                errorMessage: 'There was an error loading your payment, please refresh the page and try again.',
                showError: true
            })
        }

    }

    changeAmountDue = (e: any) => {
        this.setState({
            customPaymentAmount: e.target.value
        })
    }

    setAmountDue() {
        const paymentData = this.state.paymentData;
        const customPaymentAmount = this.state.customPaymentAmount;

        if (paymentData !== null && customPaymentAmount >= paymentData.MinimumPaymentAmount && customPaymentAmount <= paymentData.MaximumPaymentAmount) {
            this.setState({
                loadingPaymentRequest: true
            });

            PaymentService.confirmPaymentRequestAmount(this.props.apirUrl, this.props.token, customPaymentAmount, 1).then((response: any) => {
                paymentData.AmountDueGST = response.AmountGST;
                paymentData.AmountDueincGST = response.Amount;

                this.setState({
                    loadingPaymentRequest: false,
                    paymentData: paymentData,
                    customPaymentAmountError: ''
                })
            });
        }
        else {
            this.setState({
                loadingPaymentRequest: false,
                customPaymentAmountError: `The entered amount must be more than $${paymentData?.MinimumPaymentAmount.toFixed(2)} and less than $${paymentData?.MaximumPaymentAmount.toFixed(2)}`
            })
        }
    }

    toggleModal() {
        this.setState({
            showModal: !this.state.showModal
        })
    }


    getPaymentUrl(showSurcharge: boolean, isAfterpay: boolean) {
        if (isAfterpay) {
            if (this.state.paymentData !== null) {
                PaymentService.startAfterpayPaymentRequest(this.props.apirUrl, this.props.token, this.state.paymentData.AmountDueincGST).then((response: any) => {
                    if (response.Success) {
                        if (response.Data.Url) {
                            window.location.replace(response.Data.Url)
                        } else {
                            this.setState({
                                loadingPaymentRequest: false,
                                showError: true,
                                errorMessage: 'Uh oh! There was an error retrieving your afterpay payment link. Please call us on <a href="tel:0800650111">0800 650 111</a> and we will get to the bottom of the problem.'
                            });

                        }
                    } else {
                        this.setState({
                            loadingPaymentRequest: false,
                            showError: true,
                            errorMessage: 'Uh oh! There was an error retrieving your afterpay payment link. Please call us on <a href="tel:0800650111">0800 650 111</a> and we will get to the bottom of the problem.'
                        });
                    }
                })
            }
        } else {
            this.getPaymentExpressUrl(showSurcharge ? 1 : 2);
        }
    }

    getPaymentExpressUrl(paymentMethod: number) {
        this.setState({
            loadingPaymentRequest: true
        });

        // Check if we need to auth and purchase or just purchase
        if (paymentMethod === 1) {
            if (this.state.paymentData?.IsLayBy && !this.state.paymentData?.isDeposit) {
                paymentMethod = 3;
            }
        }

        if (this.state.selectedPaymentOption === PaymentOptions.Gem) {
            if (this.state.paymentData?.IsLayBy && !this.state.paymentData?.isDeposit) {
                paymentMethod = 3;
            } else {
                paymentMethod = 1
            }            
        }

        if (this.state.paymentData !== null) {
            PaymentService.startPaymentRequest(this.props.apirUrl, this.props.token, this.state.paymentData.AmountDueincGST, paymentMethod).then((response: any) => {
                if (response.Success) {
                    if (response.Data.url) {
                        window.location.replace(response.Data.url)
                    } else {
                        this.setState({
                            loadingPaymentRequest: false,
                            showError: true,
                            errorMessage: 'Uh oh! There was an error retrieving your payment link. Please call us on <a href="tel:0800650111">0800 650 111</a> and we will get to the bottom of the problem.'
                        });
                    }
                } else {
                    this.setState({
                        loadingPaymentRequest: false,
                        showError: true,
                        errorMessage: 'Uh oh! There was an error retrieving your payment link. Please call us on <a href="tel:0800650111">0800 650 111</a> and we will get to the bottom of the problem.'
                    });
                }
            });
        }
    }

    submitPaymentRequest() {
        this.setState({
            loadingPaymentRequest: true
        });
    }



    switchPaymentOptions = (paymentOption: number, description:string, paymentData: PaymentRequestModel) => {
        this.setState({
          selectedPaymentOption: paymentOption
        });
      }

    render() {
        const { loadingPayment, paymentData, userCanProceed, errorMessage, loadingPaymentRequest } = this.state;
        const { a2aDescription, ccDescription, afterpayDescription, gemDescription } = this.props;


        return (
            <Form>
                <Grid>
                    {
                        loadingPayment &&
                        <Grid.Column width={16} textAlign='left'>
                            <H3>
                                Loading Payment
                            <LoadingIcon name='circle notch' size='huge' loading />
                            </H3>
                        </Grid.Column>
                    }
                    {
                        !loadingPayment && !userCanProceed &&
                        <Grid.Column width={16} textAlign='left'>
                            <H3>
                                <PaymentLinkMessageIcon name='times circle' size='huge' />
                                Oops!
                            </H3>
                            <Paragraph1 dangerouslySetInnerHTML={{ __html: errorMessage }}></Paragraph1>
                        </Grid.Column>
                    }
                    {
                        !loadingPayment && userCanProceed && paymentData !== null &&
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <PaymentRequestHeading>Review Order</PaymentRequestHeading>
                            </Grid.Column>
                            <Grid.Column width={16}>
                                {
                                    paymentData.Items.map((item) => {
                                        return <PaymentRequestItemsContainer>
                                            <CartLineItemRow key={item.Combination}>
                                                <Grid.Column mobile={16} tablet={16} computer={10} widescreen={10} largeScreen={10}>
                                                    <Grid>
                                                        <PlateImageSectionGridColumn mobile={16} tablet={8} computer={8} widescreen={8} largeScreen={8}>
                                                            <div>
                                                                <PlateImageSectionHeading>
                                                                    <PlateImageTitle>Front Plate</PlateImageTitle>
                                                                </PlateImageSectionHeading>
                                                                <PlateImageContainer>
                                                                    <PlateImage src={item.ImageUrl} width={item.SizeWidth} centered alt="front plate image" />
                                                                </PlateImageContainer>
                                                            </div>
                                                            <div>
                                                                <PlateDetailSection>
                                                                    <PlateDetailSectionHeading>
                                                                        <HeadingRangeName>
                                                                            {item.RangeName}
                                                                        </HeadingRangeName>
                                                                        <PlateDetailSectionSize>
                                                                            {item.SizeWidth}x{item.SizeHeight}mm
                                                                    </PlateDetailSectionSize>
                                                                    </PlateDetailSectionHeading>
                                                                    <PlateDetailsGroup>
                                                                        <PlateDetailsGroupLabel>
                                                                            Plate Design
                                                                    </PlateDetailsGroupLabel>
                                                                        <PlateDetailsGroupField>
                                                                            {item.DesignName}
                                                                        </PlateDetailsGroupField>
                                                                    </PlateDetailsGroup>
                                                                </PlateDetailSection>
                                                            </div>
                                                        </PlateImageSectionGridColumn>
                                                        <PlateImageSectionGridColumn mobile={16} tablet={8} computer={8} widescreen={8} largeScreen={8}>
                                                            <PlateImageSectionHeading>
                                                                <PlateImageTitle>Back Plate</PlateImageTitle>
                                                            </PlateImageSectionHeading>
                                                            <PlateImageContainer>
                                                                <PlateImage src={item.DualPlateImageUrl !== null ? item.DualPlateImageUrl : item.ImageUrl} centered width={item.DualPlateSizeWidth !== null ? item.DualPlateSizeWidth : item.SizeWidth} alt="back plate image" />
                                                            </PlateImageContainer>
                                                            <PlateDetailSection>
                                                                <PlateDetailSectionHeading>
                                                                    <HeadingRangeName>
                                                                        {item.DualPlateRangeName !== null ? item.DualPlateRangeName : item.RangeName}
                                                                    </HeadingRangeName>
                                                                    <PlateDetailSectionSize>
                                                                        {item.DualPlateSizeWidth !== null ? item.DualPlateSizeWidth : item.SizeWidth}x{item.DualPlateSizeHeight !== null ? item.DualPlateSizeHeight : item.SizeHeight}mm
                                                                </PlateDetailSectionSize>
                                                                </PlateDetailSectionHeading>
                                                                <PlateDetailsGroup>
                                                                    <PlateDetailsGroupLabel>
                                                                        Plate Design
                                                                </PlateDetailsGroupLabel>
                                                                    <PlateDetailsGroupField>
                                                                        {item.DualPlateDesignName !== null ? item.DualPlateDesignName : item.DesignName}
                                                                    </PlateDetailsGroupField>
                                                                </PlateDetailsGroup>
                                                            </PlateDetailSection>
                                                        </PlateImageSectionGridColumn>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column mobile={16} tablet={16} computer={3} widescreen={3} largeScreen={3} textAlign="left">
                                                    <Responsive as={Grid} maxWidth={Responsive.onlyComputer.minWidth}>
                                                        <Grid.Column mobile={16} tablet={8}>
                                                            <PlateDetailSectionHeading>
                                                                <HeadingRangeName>
                                                                    Combination
                                                            </HeadingRangeName>
                                                                <PlateDetailSectionSize>
                                                                    {item.Combination}
                                                                </PlateDetailSectionSize>
                                                            </PlateDetailSectionHeading>
                                                        </Grid.Column>
                                                    </Responsive>
                                                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                                        <CombinationNumber>{item.Combination}</CombinationNumber>
                                                        <PurchaseTypeName>{item.TransactionType}</PurchaseTypeName>
                                                    </Responsive>
                                                </Grid.Column>

                                                <Responsive as={Grid.Column} mobile={16} tablet={16} maxWidth={Responsive.onlyComputer.minWidth}>
                                                    <Grid>
                                                        <Grid.Column mobile={16} tablet={8}>
                                                            <PlateDetailSectionHeading>
                                                                <HeadingRangeName>
                                                                    Type
                                                            </HeadingRangeName>
                                                                <PlateDetailSectionSize>
                                                                    {item.TransactionType}
                                                                </PlateDetailSectionSize>
                                                            </PlateDetailSectionHeading>
                                                        </Grid.Column>
                                                    </Grid>
                                                </Responsive>

                                                <Grid.Column mobile={16} tablet={16} computer={2} widescreen={2} largeScreen={2} textAlign="left">
                                                    <Responsive as={Grid} maxWidth={Responsive.onlyComputer.minWidth}>
                                                        <Grid.Column mobile={16} tablet={8}>
                                                            <PlateDetailSectionHeading>
                                                                <HeadingRangeName>
                                                                    Price
                                                            </HeadingRangeName>
                                                                <PlateDetailSectionSize>
                                                                    <PlateItemPriceComputer available={true}>${item.AmountincGST.toFixed(2)}</PlateItemPriceComputer>
                                                                </PlateDetailSectionSize>
                                                            </PlateDetailSectionHeading>
                                                        </Grid.Column>
                                                    </Responsive>
                                                    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                                                        <PlateItemPrice available={true}>${item.AmountincGST.toFixed(2)}</PlateItemPrice>
                                                    </Responsive>

                                                </Grid.Column>
                                            </CartLineItemRow>
                                        </PaymentRequestItemsContainer>
                                    })
                                }
                            </Grid.Column>
                            {
                                paymentData.IsLayBy &&
                                <Grid.Column width={16}>
                                    <PaymentRequestHeading>Confirm Amount</PaymentRequestHeading>
                                    <Paragraph1>
                                        Your contribution must be at least ${paymentData.MinimumPaymentAmount.toFixed(2)} however, you can choose to make a larger payment (up to ${paymentData.MaximumPaymentAmount.toFixed(2)}) and complete your Layby earlier.
                                </Paragraph1>
                                </Grid.Column>
                            }
                            {
                                paymentData.IsLayBy &&
                                <PaymentAmountContainer width={6}>
                                    <KpFormField>
                                        <label htmlFor='customPaymentAmount'>PAYMENT AMOUNT</label>
                                        <Input
                                            id='customPaymentAmount'
                                            name='customPaymentAmount'
                                            type='number'
                                            aria-label='Custom Payment Amount'
                                            disabled={loadingPaymentRequest}
                                            onChange={this.changeAmountDue}
                                            value={this.state.customPaymentAmount}>
                                        </Input>
                                    </KpFormField>
                                    <div>
                                        <KpButton id='setpayamount' buttonType='primary' onClick={() => this.setAmountDue()} loading={this.state.loadingPaymentRequest} color={theme.brand.colors.blue}>
                                            Set Payment Amount
                                    </KpButton>
                                        {
                                            this.state.customPaymentAmountError !== '' &&
                                            <Paragraph2 color='red'>
                                                {this.state.customPaymentAmountError}
                                            </Paragraph2>
                                        }
                                    </div>
                                </PaymentAmountContainer>
                            }
                            <Grid.Column width={16}>
                                <PaymentRequestHeading>Choose Payment Method</PaymentRequestHeading>
                            </Grid.Column>

                            <Responsive as={GridColumn} width={16} maxWidth={theme.responsive.mobile.maxWidth}>
                                <PaymentRequestOptionRadioButton fluid
                                    onClick={() => this.switchPaymentOptions(PaymentOptions.CreditCard, ccDescription, paymentData)}
                                    active={this.state.selectedPaymentOption === PaymentOptions.CreditCard}
                                    isFirst={true}
                                    disabled={loadingPaymentRequest}>
                                    <PaymentOptionRadio
                                    checked={this.state.selectedPaymentOption === PaymentOptions.CreditCard} />
                                    Credit/Debit Card
                                </PaymentRequestOptionRadioButton>
                                <PaymentRequestOptionRadioButton fluid
                                    onClick={() => this.switchPaymentOptions(PaymentOptions.Gem, gemDescription, paymentData)}
                                    active={this.state.selectedPaymentOption === PaymentOptions.Gem}
                                    isFirst={true}
                                    disabled={loadingPaymentRequest}>
                                    <PaymentOptionRadio
                                    checked={this.state.selectedPaymentOption === PaymentOptions.Gem} />
                                    Gem Visa
                                </PaymentRequestOptionRadioButton>
                                <PaymentRequestOptionRadioButton fluid
                                    onClick={() => this.switchPaymentOptions(PaymentOptions.Account2Account, a2aDescription, paymentData)}
                                    active={this.state.selectedPaymentOption === PaymentOptions.Account2Account}
                                    isFirst={true}
                                    disabled={loadingPaymentRequest}>
                                    <PaymentOptionRadio
                                    checked={this.state.selectedPaymentOption === PaymentOptions.Account2Account} />
                                    Account 2 Account
                                </PaymentRequestOptionRadioButton>
                                <PaymentRequestOptionRadioButton fluid
                                    onClick={() => this.switchPaymentOptions(PaymentOptions.Afterpay, afterpayDescription, paymentData)}
                                    active={this.state.selectedPaymentOption === PaymentOptions.Afterpay}
                                    isFirst={true}
                                    disabled={loadingPaymentRequest || !this.state.allowAfterpay}>
                                    <PaymentOptionRadio
                                    checked={this.state.selectedPaymentOption === PaymentOptions.Afterpay} />
                                    <AfterpayLogo fill={"#000"} />
                                </PaymentRequestOptionRadioButton>
                                <TabContentOptionStyle
                                    active={this.state.selectedPaymentOption == PaymentOptions.CreditCard ? true : false}
                                    >{this.state.selectedPaymentOption === PaymentOptions.CreditCard ? this.getPaymentTab(ccDescription, paymentData, true, false) : null}</TabContentOptionStyle>
                                    <TabContentOptionStyle
                                    active={this.state.selectedPaymentOption == PaymentOptions.Gem ? true : false}
                                    >{this.state.selectedPaymentOption === PaymentOptions.Gem ? this.getPaymentTab(gemDescription, paymentData, false, false, true) : null}</TabContentOptionStyle>
                                    <TabContentOptionStyle
                                    active={this.state.selectedPaymentOption == PaymentOptions.Account2Account ? true : false}
                                    >{this.state.selectedPaymentOption === PaymentOptions.Account2Account ? this.getPaymentTab(a2aDescription, paymentData, false, false): null}</TabContentOptionStyle>
                                    <TabContentOptionStyle
                                    active={this.state.selectedPaymentOption == PaymentOptions.Afterpay ? true : false}
                                    >{this.state.selectedPaymentOption === PaymentOptions.Afterpay ? this.getPaymentTab(afterpayDescription, paymentData, false, true): null}</TabContentOptionStyle>
                            </Responsive>
                            <Responsive as={Grid} minWidth={theme.responsive.tablet.minWidth}>
                                <Grid.Column width={16}>
                                    <PaymentRequestTab panes={[
                                        {
                                            menuItem: { disabled: loadingPaymentRequest, key: 'creditcard', content: 'Credit Card' },
                                            render: () => this.getPaymentTab(ccDescription, paymentData, true, false)
                                        },
                                        {
                                            menuItem: { disabled: loadingPaymentRequest, key: 'gem', content: 'Gem Visa' },
                                            render: () => this.getPaymentTab(gemDescription, paymentData, true, false, true)
                                        },
                                        {
                                            menuItem: { disabled: loadingPaymentRequest, key: 'account2account', content: 'Account 2 Account' },
                                            render: () => this.getPaymentTab(a2aDescription, paymentData, false, false)
                                        },
                                        {
                                            menuItem: { disabled: loadingPaymentRequest || !this.state.allowAfterpay, key: 'afterpay',content: 'Afterpay' },
                                            render: () => this.getPaymentTab(afterpayDescription, paymentData, false, true)
                                        }
                                    ]} />
                                </Grid.Column>
                            </Responsive>
                        </Grid.Row>
                    }
                </Grid>
            </Form>
        );
    }

    getPaymentTab(html: string, paymentData: PaymentRequestModel, showSurcharge: boolean, isAfterpay: boolean, isGem: boolean = false) {
        const orderTotal = paymentData.AmountDueincGST;
        return <PaymentRequestContentGrid disabled={this.state.loadingPaymentRequest}>
            <PaymentRequestContentColumn mobile={16} tablet={8} computer={8}>
                <Grid>
                    <PaymentRequestSummaryRow centered>
                        <Grid.Column textAlign='left' width={16}>
                            <Paragraph1 dangerouslySetInnerHTML={{ __html: html }}></Paragraph1>
                        </Grid.Column>
                    </PaymentRequestSummaryRow>
                </Grid>
            </PaymentRequestContentColumn>
            <PaymentRequestContentColumn mobile={16} tablet={8} computer={8}>
                <Grid>
                    <PaymentRequestSummaryRow centered>
                        <Grid.Column textAlign='left' width={7}>
                            <Paragraph2 color='#707070'>
                                Items
                                </Paragraph2>
                        </Grid.Column>
                        <Grid.Column textAlign='right' width={7}>
                            <Paragraph2 color='#707070'>
                                {paymentData.Items.length}
                            </Paragraph2>
                        </Grid.Column>
                    </PaymentRequestSummaryRow>
                    <PaymentRequestSummaryRow centered>
                        <Grid.Column textAlign='left' width={7}>
                            <Paragraph2 color='#707070'>
                                Subtotal
                                </Paragraph2>
                        </Grid.Column>
                        <Grid.Column textAlign='right' width={7}>
                            <Paragraph2 color='#707070'>
                                ${(paymentData.AmountDueincGST - paymentData.AmountDueGST).toFixed(2)}
                            </Paragraph2>
                        </Grid.Column>
                    </PaymentRequestSummaryRow>
                    <PaymentRequestSummaryRow centered>
                        <Grid.Column textAlign='left' width={7}>
                            <Paragraph2 color='#707070'>
                                GST
                                </Paragraph2>
                        </Grid.Column>
                        <Grid.Column textAlign='right' width={7}>
                            <Paragraph2 color='#707070'>
                                ${paymentData.AmountDueGST.toFixed(2)}
                            </Paragraph2>
                        </Grid.Column>
                    </PaymentRequestSummaryRow>
                    {
                        showSurcharge &&
                        <PaymentRequestSummaryRow centered>
                            <Grid.Column textAlign='left' width={7}>
                                <Paragraph2 color='#707070'>
                                    Processing Fee
                                        </Paragraph2>
                            </Grid.Column>
                            <Grid.Column textAlign='right' width={7}>
                                <Paragraph2 color='#707070'>
                                    ${(orderTotal * 0.019).toFixed(2)}
                                </Paragraph2>
                            </Grid.Column>
                        </PaymentRequestSummaryRow>
                    }
                    <PaymentRequestSummaryRow centered bordered>
                        <Grid.Column textAlign='left' width={7}>
                            <Paragraph2 color='black'>
                                <strong>
                                    TOTAL
                                    </strong>
                            </Paragraph2>
                        </Grid.Column>
                        <Grid.Column textAlign='right' width={7}>
                            <Paragraph2 color='black'>
                                <strong>
                                    ${(orderTotal + (showSurcharge ? (orderTotal * 0.019) : 0)).toFixed(2)}
                                </strong>
                            </Paragraph2>
                        </Grid.Column>
                    </PaymentRequestSummaryRow>
                    <Responsive as={GridRow} minWidth={theme.responsive.tablet.minWidth}>
                        <Grid.Column textAlign='right'>
                            <KpButton id='paynow' buttonType='primary' onClick={() => this.toggleModal()} loading={this.state.loadingPaymentRequest} color={theme.brand.colors.blue}>
                                Pay Now
                            </KpButton>
                        </Grid.Column>
                    </Responsive>
                    <Responsive as={GridRow} maxWidth={theme.responsive.mobile.maxWidth}>
                        <ButtonWrapperStyle>
                            <KpButton id='paynow' fullWidth="mobile" buttonType='primary' onClick={() => this.toggleModal()} loading={this.state.loadingPaymentRequest} color={theme.brand.colors.blue}>
                                Pay Now
                            </KpButton>
                        </ButtonWrapperStyle>
                    </Responsive>

                </Grid>
            </PaymentRequestContentColumn>
            <InfoModal showCloseButton={true} content='Please note, once you’ve proceeded to the next page for payment, this page will expire. If payment is not made on the next page in this same session, you will not be able to return to this page to attempt payment.  You will need to contact customer service team on 0800 650 111 to request for a new payment link.' title='Confirm Payment' modalOpen={this.state.showModal} onClose={() => this.toggleModal()}>
                <KpButton id='paynow' buttonType='primary' onClick={() => this.getPaymentUrl(showSurcharge, isAfterpay)} loading={this.state.loadingPaymentRequest} color={theme.brand.colors.blue}>
                    Continue to Payment
                    </KpButton>
            </InfoModal>
        </PaymentRequestContentGrid>
    }
}

export default PaymentRequest