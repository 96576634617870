import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from '@components/SEO'
import { mapToPageHeader, Banner } from "@components/Banner"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { mapToKiwiVIPVisible, SignupBannerWrapper } from "@components/SignupBanner"
import PaymentRequest from "@components/MakePayment/PaymentRequest"

interface Props {
  data: any;
  location: any;
}

const queryString = require('query-string');

const loadFromQuerystring = (location: any): { token: string, fromsalesforce: boolean } => {
  const query = queryString.parse(location.search, { parseBooleans: true, parseNumbers: true });
  return {
    token: query.token,
    fromsalesforce: query.fromsalesforce ? query.fromsalesforce : false
  };
}

class MakePaymentPage extends React.Component<Props>{

  render() {
    const { elements } = this.props.data.kontentItemPaymentLinkPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const metadata = mapToPageHeader(elements);
    const seoData = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const token = loadFromQuerystring(this.props.location).token;
    const formSalesforce = loadFromQuerystring(this.props.location).fromsalesforce;

    return (<Layout version="standard">
      <SEO {...seoData} />
      <Banner {...metadata} isBiggerBanner={false} />
      <GenericBannerContainer
        padding={{
          mobile: {
            top: 60,
            bottom: 80
          },
          desktop: {
            top: 80,
            bottom: 100
          }
        }}
        backgroundColor={theme.brand.colors.beige}>
        <PaymentRequest gemDescription={elements.gem_description.value} ccDescription={elements.credit_card_description.value} a2aDescription={elements.account_2_account_description.value} afterpayDescription={elements.afterpay_description.value} apirUrl={apiUrl} token={token} fromSalesforce={formSalesforce}></PaymentRequest>
      </GenericBannerContainer>
      {
        kiwiVip.visible &&
        <SignupBannerWrapper version={kiwiVip.version} />
      }
    </Layout>
    );
  }
}

export const query = graphql`{
    site {
        siteMetadata {
          apiUrl
        }
      }
    kontentItemPaymentLinkPage {
      elements {
        account_2_account_description {
          value
        }
        gem_description {
          value
        }
        credit_card_description {
          value
        }
        afterpay_description {
          value
        }
        general_page_content__header_image {
          value {
            description
            height
            name
            size
            type
            url
            width
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url {
          value
        }
      }
    }
  }
`

export default MakePaymentPage